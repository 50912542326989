import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'
import { Checkbox, Typography } from 'antd'
import { getEditReplaceMatches, getIsReplaceAllOnEditSelected } from 'redux/selectors/audio-pipeline'
import { setReplaceAllOnEdit } from 'redux/actions/audio-pipeline'
import styles from './styles.module.css'

const { Text } = Typography

export const ReplaceAllCheckbox: React.FC = () => {
  const dispatch = useDispatch()
  const shouldReplaceAllOnEdit = useSelector(getIsReplaceAllOnEditSelected)
  const matches = useSelector(getEditReplaceMatches)
  const matchCount = matches.length

  const handleCheckboxClick = useCallback(() => {
    dispatch(setReplaceAllOnEdit(!shouldReplaceAllOnEdit))
  }, [dispatch, shouldReplaceAllOnEdit])

  return (
    <div className={styles.wrapper}>
      <Checkbox
        className={classNames(styles.checkbox, {
          [styles.checked]: shouldReplaceAllOnEdit
        })}
        checked={shouldReplaceAllOnEdit}
        onClick={handleCheckboxClick}
      >
        Replace all
      </Checkbox>
      {matchCount && <Text>({matches.length})</Text>}
    </div>
  )
}