import React, { useCallback } from 'react'
import { EditOutlined } from '@ant-design/icons'
import { Button } from 'components/common/general/Button'
import { useDispatch, useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { getEditedWordId } from 'redux/selectors/audio-pipeline'
import {
  revertEditedWord,
  setEditedWord,
  startEditWord,
  stopEditWord
} from 'redux/actions/audio-pipeline'
import styles from './styles.module.css'

interface Props {
  id: string
  wordText: string
  editedWordText: string
  startTime: number
  endTime: number
}

export const EditWordButton: React.FC<Props> = ({
  id,
  wordText,
  editedWordText,
  startTime,
  endTime
}) => {
  const dispatch = useDispatch()
  const { formatMessage } = useIntl()
  const editedWordId = useSelector(getEditedWordId)
  const isEditing = id === editedWordId
  const editButtonText = formatMessage({
    id: isEditing
      ? 'component.audio-pipeline.transcript.edit.done'
      : 'component.audio-pipeline.transcript.edit'
  })

  const handleDoneEditingClick = useCallback(() => {
    if (editedWordId) {
      dispatch(stopEditWord(editedWordId))
    }

    // if (editedWordText === wordText) {
    //   return dispatch(revertEditedWord(id))
    // }
    //
    // dispatch(setEditedWord({ id, text: editedWordText, startTime, endTime }))
  }, [id, wordText, editedWordText, startTime, endTime, dispatch])

  const handleEditClick = useCallback(() => {
      if (!isEditing) {
        return dispatch(startEditWord(id))
      }

      handleDoneEditingClick()

    },
    [id, isEditing, handleDoneEditingClick, dispatch])

  return (
    <Button
      className={styles.wrapper}
      text={editButtonText}
      icon={<EditOutlined />}
      onClick={handleEditClick}
    />
  )
}