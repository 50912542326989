import React, { useCallback, useMemo } from 'react'
import classNames from 'classnames'
import { Componentify } from 'components/common/other/Componentify'
import { useDispatch, useSelector } from 'react-redux'
import { Typography } from 'antd'
import {
  getEditedWordId,
  getEditedWords
} from 'redux/selectors/audio-pipeline'
import {
  getTranscriptionSearchMatchedWordIds,
  getSearchSelectedMatchGroupWordIds,
  getTranscriptionSearchText
} from 'redux/selectors/transcript-search'
import { setPlayerSelectedWordId } from 'redux/actions/audio-pipeline'
import styles from './styles.module.css'

const { Text } = Typography

interface Props {
  id: string
  text: string
  isPassed?: boolean,
  isActive?: boolean,
}

export const TranscriptWordTextContent: React.FC<Props> = ({
  id,
  text,
  isPassed,
  isActive
}) => {
  const dispatch = useDispatch()
  const editedWordId = useSelector(getEditedWordId)
  const editedWordsById = useSelector(getEditedWords)
  const searchMatchedWordIds = useSelector(getTranscriptionSearchMatchedWordIds)
  const searchText = useSelector(getTranscriptionSearchText)
  const selectedSearchGroupWords = useSelector(getSearchSelectedMatchGroupWordIds)
  const isEditing = id === editedWordId
  const editedWord = editedWordsById[id]
  const wordText = editedWord?.text || text
  const displayText = `${wordText} `
  const isMatchSearch = searchMatchedWordIds.includes(id)
  const isSearchSelected = selectedSearchGroupWords.includes(id)
  const searchHighlightPatterns = useMemo(() => searchText
      ? searchText.split(/\s/)
      : [],
    [searchText])

  const handleMatch = useCallback(({ text, index }: {
    text: string
    index: number
  }) => <Text
    key={`${text}-${index}`}
    className={styles.searchMatch}
  >
    {text}
  </Text>, [])

  const handleClick = useCallback(() => {
    dispatch(setPlayerSelectedWordId(id))
  }, [id, dispatch])

  return (
    <span
      className={classNames(styles.wrapper, {
        [styles.passed]: isPassed,
        [styles.highlighted]: isActive,
        [styles.editing]: isEditing,
        [styles.edited]: !!editedWord,
        [styles.searchSelected]: isSearchSelected,
      })}
      onClick={handleClick}
    >
        {isMatchSearch
          ? <Componentify
            text={displayText}
            className={styles.text}
            patterns={searchHighlightPatterns}
            onMatch={handleMatch}
          />
          : displayText
        }
      </span>
  )
}