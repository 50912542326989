import { all, call, cancel, delay, fork, put, race, select, take, takeLatest } from 'redux-saga/effects'
import { Channel, channel } from 'redux-saga'
import axios from 'axios'
import { PayloadAction } from '@reduxjs/toolkit'
import { LanguageSelectOptionIds } from 'components/audio-pipeline/FileUpload/LanguageSelect/types'
import {
  AudioPipelineTranscriptionResponse,
  AudioPipelineTranscriptionResponseStatus
} from 'constants/audio-pipeline/types'
import {
  ToggleEditWordPayload,
  StartPollingTranscriptionPayload
} from 'redux/actions/audio-pipeline/types'
import { Loaders } from 'redux/reducers/loaders/types'
import { HTTPMethods } from 'api/types'
import {
  COPY_TRANSCRIPT_TEXT,
  GENERATE_ARTICLE,
  GENERATE_HEADLINE,
  GENERATE_SEO_TAGS,
  START_EDIT_WORD,
  START_PLAYBACK,
  START_POLLING_TRANSCRIPTION, STOP_EDIT_WORD,
  STOP_PLAYBACK,
  STOP_POLLING_TRANSCRIPTION,
  SUMMARISE,
  TRANSCRIBE,
  UPLOAD_FILE
} from 'redux/actions/audio-pipeline/constants'
import { AUDIO_PLAYBACK_UPDATE_INTERVAL, POLLING_INTERVAL, POLLING_TIMEOUT } from 'redux/sagas/audio-pipeline/constants'
import { AUDIO_PLAYER_ID } from 'components/audio-pipeline/FileUpload/FileUploadField/constants'
import api from 'api'
import {
  getEditedWordText,
  getEditReplaceMatches,
  getFileUrl,
  getFormattedTranscribedText,
  getHeadlineTailoringText,
  getIsReplaceAllOnEditSelected,
  getSelectedEditedWord,
  getSelectedEditedWordOriginalText,
  getSelectedFile,
  getSelectedLanguage
} from 'redux/selectors/audio-pipeline'
import {
  consumeTranscription,
  setFileUploadProgress,
  setGeneratedArticleText,
  setGeneratedHeadlineText,
  setPlayerCurrentTime,
  setSEOTagsText,
  setSummarisedText,
  startPollingTranscription,
  stopPlayback,
  stopPollingTranscription,
  clearInputFields,
  setEditedWordId,
  setEditedWordText,
  setEditedWord,
  revertEditedWord,
  setReplaceAllOnEdit
} from 'redux/actions/audio-pipeline'
import { startLoading, stopLoading } from 'redux/actions/loaders'
import { handleError } from 'redux/actions/errors'
import { mapTranscriptionResponse } from 'utils/audio-pipeline/mappers/transcription'
import { addNotification } from 'redux/actions/notifications'
import { NotificationType } from 'redux/reducers/notifications/types'
import { intl } from 'utils/common/intl'
import { NotificationIds } from 'components/notification/Notifications/constants'
import { startPollingSystemMessages, stopPollingSystemMessages } from 'redux/actions/system-messages'
import { TranscriptionWord } from 'components/audio-pipeline/TranscribedText/Transcript/types'

export function* watchUploadFileChannel(ch: Channel<any>) {
  while (true) {
    const progress: number = yield take(ch)
    yield put(setFileUploadProgress(progress))
  }
}

function* handleUploadFile() {
  const file: File | null = yield select(getSelectedFile)
  const lang: LanguageSelectOptionIds = yield select(getSelectedLanguage)
  const ch: Channel<any> = yield call(channel)
  let task = null

  if (!file) {
    return
  }

  yield put(startLoading(Loaders.UploadFile))
  yield put(setFileUploadProgress(0))

  try {
    const { data } = yield call(api.getSignedUrl, {
      queryParams: {
        lang
      }
    })

    if (data) {
      const { url, id } = data
      // @ts-ignore
      task = yield fork(watchUploadFileChannel, ch)

      yield call([axios, axios.request], {
        method: HTTPMethods.PUT,
        url: url,
        data: file,
        headers: {
          'Content-Type': file.type
        },
        onUploadProgress: ({ progress }) => {
          if (progress) {
            ch.put(progress)
          }
        }
      })

      yield put(startPollingTranscription(id))
    }
  } catch (err: any) {
    yield put(handleError(err))
  } finally {
    if (task) {
      yield cancel(task)
    }

    yield put(stopLoading(Loaders.UploadFile))
  }
}

function* handleTranscribeUrl() {
  const url: string = yield select(getFileUrl)
  const lang: LanguageSelectOptionIds = yield select(getSelectedLanguage)

  try {
    const { data } = yield call(api.transcribe, {
      body: {
        url,
        lang: lang === LanguageSelectOptionIds.direct
          ? lang
          : LanguageSelectOptionIds.en_GB
      }
    })

    if (data && data.id) {
      yield put(startPollingTranscription(data.id))
    }
  } catch (err: any) {
    yield put(handleError(err))
  }
}

function* handleTranscribe() {
  const url: string = yield select(getFileUrl)
  const file: File | null = yield select(getSelectedFile)

  yield put(clearInputFields())

  if (url) {
    yield call(handleTranscribeUrl)
  }

  if (file) {
    yield call(handleUploadFile)
  }
}

function* handleFetchTranscriptionData(id: string) {
  try {
    const lang: LanguageSelectOptionIds = yield select(getSelectedLanguage)
    const { data } = yield call(api.getTranscription, {
      urlParams: { id }
    })

    if (!data) {
      return
    }

    const { status, transcription } = data as AudioPipelineTranscriptionResponse

    if (status === AudioPipelineTranscriptionResponseStatus.Error) {
      // TODO: consume error
      console.error('Error')
    }

    if (transcription) {
      yield put(consumeTranscription(mapTranscriptionResponse({ transcription, lang })))
      yield put(stopPollingTranscription())
    }
  } catch (err) {
    console.error(err)
  }
}

function* handleTranscriptionDataPolling(id: string) {
  while (true) {
    yield fork(handleFetchTranscriptionData, id)
    yield delay(POLLING_INTERVAL)
  }
}

function* handleStartPollingTranscription({ payload: { id } }: PayloadAction<StartPollingTranscriptionPayload>) {
  yield put(startLoading(Loaders.TranscriptionData))
  yield put(startPollingSystemMessages(id))

  yield race({
    polling: call(handleTranscriptionDataPolling, id),
    cancel: take(STOP_POLLING_TRANSCRIPTION),
    timeout: delay(POLLING_TIMEOUT)
  })

  yield put(stopPollingSystemMessages())
  yield put(stopLoading(Loaders.TranscriptionData))
}

function* handleSummarise() {
  const text: string = yield select(getFormattedTranscribedText)

  yield put(startLoading(Loaders.Summarise))

  try {
    const { data } = yield call(api.summarise, {
      body: {
        text
      }
    })

    if (data) {
      yield put(setSummarisedText(data))
    }
  } catch (err: any) {
    yield put(handleError(err))
  } finally {
    yield put(stopLoading(Loaders.Summarise))
  }
}

function* handleGenerateArticle() {
  const text: string = yield select(getFormattedTranscribedText)

  yield put(startLoading(Loaders.GenerateArticle))

  try {
    const { data } = yield call(api.generateArticle, {
      body: {
        text
      }
    })

    if (data) {
      yield put(setGeneratedArticleText(data))
    }
  } catch (err: any) {
    yield put(handleError(err))
  } finally {
    yield put(stopLoading(Loaders.GenerateArticle))
  }
}

function* handleGenerateSEOTags() {
  const text: string = yield select(getFormattedTranscribedText)

  yield put(startLoading(Loaders.GenerateSEOTags))

  try {
    const { data } = yield call(api.generateSEOTags, {
      body: {
        text
      }
    })

    if (data) {
      yield put(setSEOTagsText(data))
    }
  } catch (err: any) {
    yield put(handleError(err))
  } finally {
    yield put(stopLoading(Loaders.GenerateSEOTags))
  }
}

function* handleGenerateHeadline() {
  const text: string = yield select(getFormattedTranscribedText)
  const keyWords: string = yield select(getHeadlineTailoringText)

  yield put(startLoading(Loaders.GenerateHeadline))

  try {
    const { data } = yield call(api.generateHeadline, {
      body: {
        text,
        keyWords
      }
    })

    if (data) {
      yield put(setGeneratedHeadlineText(data))
    }
  } catch (err: any) {
    yield put(handleError(err))
  } finally {
    yield put(stopLoading(Loaders.GenerateHeadline))
  }
}

const playbackTimeupdateInterval = function* () {
  const audio = document.getElementById(AUDIO_PLAYER_ID) as HTMLAudioElement

  while (true) {
    if (!audio || audio.paused) {
      yield put(stopPlayback())

      return null
    }

    yield put(setPlayerCurrentTime(audio.currentTime))
    yield delay(AUDIO_PLAYBACK_UPDATE_INTERVAL)
  }
}

const handleStartPlaybackTimeupdate = function* () {
  yield race({
    interval: call(playbackTimeupdateInterval),
    cancel: take(STOP_PLAYBACK)
  })
}

const handleCopyTranscriptText = function* () {
  const text: string = yield select(getFormattedTranscribedText)

  try {
    yield call([navigator.clipboard, navigator.clipboard.writeText], text)

    yield put(addNotification({
      id: NotificationIds.CopyToClipboardSuccess,
      type: NotificationType.Success,
      message: intl.formatMessage({
        id: 'component.common.copy-to-clipboard.success'
      })
    }))
  } catch (err) {
    console.error(err)
  }
}

const handleStartEditWord = function* ({ payload: { id } }: PayloadAction<ToggleEditWordPayload>) {
  yield put(setEditedWordId(id))

  const word: TranscriptionWord | undefined = yield select(getSelectedEditedWord)

  if (word) {
    const { text } = word

    yield put(setEditedWordText(text))
  }
}

const handleApplyEdit = function* ({
  word,
  text
}: {
  word: TranscriptionWord,
  text: string
}) {
  const shouldReplaceAllMatches: boolean = yield select(getIsReplaceAllOnEditSelected)
  const matches: TranscriptionWord[] = yield select(getEditReplaceMatches)
  const wordsToReplace = shouldReplaceAllMatches
    ? matches
    : [word]

  for (const wordToReplace of wordsToReplace) {
    const { id, startTime, endTime } = wordToReplace

    yield put(setEditedWord({
      id,
      text,
      startTime,
      endTime
    }))
  }
}

const handleStopEditWord = function* ({ payload: { id } }: PayloadAction<ToggleEditWordPayload>) {
  const word: TranscriptionWord | undefined = yield select(getSelectedEditedWord)
  const originalText: string | undefined = yield select(getSelectedEditedWordOriginalText)
  const editedWordText: string = yield select(getEditedWordText)
  const isTextChanged = word && word.text !== editedWordText
  const shouldRevertToOriginal = originalText === editedWordText
  const shouldApplyEdit = isTextChanged && !shouldRevertToOriginal

  if (shouldApplyEdit) {
    yield call(handleApplyEdit, { word, text: editedWordText })
  }

  if (shouldRevertToOriginal) {
    yield put(revertEditedWord(id))
  }

  yield put(setEditedWordId(null))
  yield put(setEditedWordText(''))
  yield put(setReplaceAllOnEdit(false))
}

export function* audioPipeline() {
  yield all([
    takeLatest(UPLOAD_FILE, handleUploadFile),
    takeLatest(TRANSCRIBE, handleTranscribe),
    takeLatest(SUMMARISE, handleSummarise),
    takeLatest(GENERATE_ARTICLE, handleGenerateArticle),
    takeLatest(GENERATE_SEO_TAGS, handleGenerateSEOTags),
    takeLatest(GENERATE_HEADLINE, handleGenerateHeadline),
    takeLatest(START_POLLING_TRANSCRIPTION, handleStartPollingTranscription),
    takeLatest(START_PLAYBACK, handleStartPlaybackTimeupdate),
    takeLatest(COPY_TRANSCRIPT_TEXT, handleCopyTranscriptText),
    takeLatest(START_EDIT_WORD, handleStartEditWord),
    takeLatest(STOP_EDIT_WORD, handleStopEditWord)
  ])
}