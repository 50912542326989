import { createAction } from '@reduxjs/toolkit'
import {
  PrepareSetSearchText,
  PrepareSetSearchSelectedMatchId
} from 'redux/actions/transcript-search/types'
import {
  SET_SEARCH_TEXT,
  SET_SEARCH_SELECTED_MATCH_ID
} from 'redux/actions/transcript-search/constants'

export const setSearchText = createAction<PrepareSetSearchText>(
  SET_SEARCH_TEXT,
  (text: string) => ({
    payload: { text }
  }))

export const setSearchSelectedMatchId = createAction<PrepareSetSearchSelectedMatchId>(
  SET_SEARCH_SELECTED_MATCH_ID,
  (id: string | null) => ({
    payload: { id }
  }))