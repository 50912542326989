import React from 'react'
import { useSelector } from 'react-redux'
import { Typography } from 'antd'
import {
  getTranscriptionSearchMatchGroupIds,
  getTranscriptionSearchSelectedMatchId
} from 'redux/selectors/transcript-search'
import { constrain } from 'utils/common/other'
import styles from './styles.module.css'

const { Text } = Typography

export const MatchCount: React.FC = () => {
  const matchGroupIds = useSelector(getTranscriptionSearchMatchGroupIds)
  const selectedMatchId = useSelector(getTranscriptionSearchSelectedMatchId)
  const currentSelectedMatchIndex = constrain({
    min: 0,
    max: matchGroupIds.length - 1,
    value: selectedMatchId ? matchGroupIds.indexOf(selectedMatchId) : 0
  })
  const text = `${currentSelectedMatchIndex + 1}/${matchGroupIds.length}`

  return (
    <Text className={styles.wrapper}>{text}</Text>
  )
}