import React, { useCallback } from 'react'
import { CloseCircleFilled  } from '@ant-design/icons'
import { Button } from 'antd'
import { setSearchText } from 'redux/actions/transcript-search'
import { useDispatch } from 'react-redux'
import styles from './styles.module.css'

export const ClearButton: React.FC = () => {
  const dispatch = useDispatch()

  const handleClear = useCallback(() => {
    dispatch(setSearchText(''))
  }, [dispatch])

  return (
    <Button
      type='text'
      className={styles.wrapper}
      icon={<CloseCircleFilled className={styles.icon} />}
      onClick={handleClear}
    />
  )
}