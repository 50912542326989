import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ReducersType } from 'redux/reducers/types'
import { TranscriptSearchInitialState } from 'redux/reducers/transcript-search/constants'
import { SetSearchSelectedMatchIdPayload, SetSearchTextPayload } from 'redux/actions/transcript-search/types'

export const transcriptSearch = createSlice({
  name: ReducersType.TranscriptSearch,
  initialState: TranscriptSearchInitialState,
  reducers: {
    setSearchText: (state, { payload: { text } }: PayloadAction<SetSearchTextPayload>) => {
      state.text = text
    },
    setSearchSelectedMatchId: (state, { payload: { id } }: PayloadAction<SetSearchSelectedMatchIdPayload>) => {
      state.selectedMatchId = id
    }
  }
})