import React, { useCallback, useEffect, useState } from 'react'
import { Input } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { EditWordButton } from 'components/audio-pipeline/TranscribedText/Transcript/WordTooltipContent/EditButton'
import {
  getEditedWordId,
  getEditedWords,
  getEditedWordText,
  getEditReplaceMatches
} from 'redux/selectors/audio-pipeline'
import {
  ReplaceAllCheckbox
} from 'components/audio-pipeline/TranscribedText/Transcript/WordTooltipContent/ReplaceAllCheckbox'
import styles from './styles.module.css'
import { setEditedWordText } from 'redux/actions/audio-pipeline'

interface Props {
  id: string
  text: string
  startTime: number
  endTime: number
}

export const EditWord: React.FC<Props> = ({
  id,
  text,
  startTime,
  endTime
}) => {
  const dispatch = useDispatch()
  const editedWordId = useSelector(getEditedWordId)
  const editedWordText = useSelector(getEditedWordText)
  const wordMatches = useSelector(getEditReplaceMatches)
  const isEditing = id === editedWordId

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setEditedWordText(e.target.value))
  }, [dispatch])

  return (
    <div className={styles.wrapper}>
      {isEditing &&
        <Input
          className={styles.input}
          value={editedWordText}
          onChange={handleChange}
        />
      }
      {wordMatches.length > 1 && <ReplaceAllCheckbox />}
      <EditWordButton
        id={id}
        wordText={text}
        editedWordText={editedWordText}
        startTime={startTime}
        endTime={endTime}
      />
    </div>
  )
}