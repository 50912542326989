import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'antd'
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons'
import { setSearchSelectedMatchId } from 'redux/actions/transcript-search'
import {
  getTranscriptionSearchMatchGroupIds,
  getTranscriptionSearchSelectedMatchId
} from 'redux/selectors/transcript-search'
import { constrain } from 'utils/common/other'
import styles from './styles.module.css'

export const PrevNextControls: React.FC = () => {
  const dispatch = useDispatch()
  const matchGroupIds = useSelector(getTranscriptionSearchMatchGroupIds)
  const selectedMatchId = useSelector(getTranscriptionSearchSelectedMatchId)
  const maxSelectedIndex = constrain({
    min: 0,
    max: matchGroupIds.length,
    value: matchGroupIds.length - 1
  })
  const currentSelectedMatchIndex = constrain({
    min: 0,
    max: maxSelectedIndex,
    value: selectedMatchId ? matchGroupIds.indexOf(selectedMatchId) : 0
  })

  const handleNextClick = useCallback(() => {
    const nextSelectedId = matchGroupIds[currentSelectedMatchIndex + 1]

    if (nextSelectedId) {
      dispatch(setSearchSelectedMatchId(nextSelectedId))
    }

  }, [currentSelectedMatchIndex, matchGroupIds, dispatch])

  const handlePrevClick = useCallback(() => {
    const index = currentSelectedMatchIndex ? currentSelectedMatchIndex - 1 : 0
    const prevSelectedId = matchGroupIds[index]

    if (prevSelectedId) {
      dispatch(setSearchSelectedMatchId(prevSelectedId))
    }

  }, [currentSelectedMatchIndex, matchGroupIds, dispatch])

  return (
    <div className={styles.wrapper}>
      <Button
        type='text'
        disabled={!currentSelectedMatchIndex}
        icon={<ArrowUpOutlined className={styles.icon}/>}
        className={styles.button}
        onClick={handlePrevClick}
      />
      <Button
        type='text'
        disabled={currentSelectedMatchIndex === maxSelectedIndex}
        icon={<ArrowDownOutlined className={styles.icon}/>}
        className={styles.button}
        onClick={handleNextClick}
      />
    </div>
  )
}