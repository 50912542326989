import { RootState } from 'redux/types/store'
import { TranscriptionSearchMatchGroup } from 'redux/reducers/transcript-search/types'
import { createSelector } from 'reselect'
import { getEditedWords, getTranscriptionParagraphs, getTranscriptionWords } from 'redux/selectors/audio-pipeline'
import { getSearchTextMatches } from 'redux/selectors/transcript-search/utils'

export const getTranscriptionSearchText = (state: RootState) => state.transcriptSearch.text
export const getTranscriptionSearchSelectedMatchId = (state: RootState) => state.transcriptSearch.selectedMatchId

export const getSearchWordMatches = createSelector(
  getTranscriptionSearchText,
  getTranscriptionWords,
  getEditedWords,
  (searchText, words, editedWordsById) => {
    if (!searchText) {
      return []
    }

    return getSearchTextMatches({ words, searchText })
  }
)

export const getTranscriptionSearchMatchedWordIds = createSelector(
  getSearchWordMatches,
  (words) => words.map(({ id }) => id)
)

export const getTranscriptionSearchMatchGroupsById = createSelector(
  getSearchWordMatches,
  words =>
    words
      .reduce((acc: Record<string, TranscriptionSearchMatchGroup>, word) => {
        const { groupId } = word

        if (!acc[groupId]) {
          acc[groupId] = {
            id: groupId,
            words: []
          }
        }

        acc[groupId].words.push(word)

        return acc
      }, {} as Record<string, TranscriptionSearchMatchGroup>)
)

export const getTranscriptionSearchMatchGroupIds = createSelector(
  getTranscriptionSearchMatchGroupsById,
  groups =>
    Object
      .values(groups)
      .map(({ id }) => id)
)

export const getSearchSelectedMatchGroupWords = createSelector(
  getTranscriptionSearchSelectedMatchId,
  getTranscriptionSearchMatchGroupsById,
  (selectedGroupId, matchesById) => {
    const group = selectedGroupId && matchesById[selectedGroupId]

    if (!group) {
      return []
    }

    return group.words
  }
)

export const getSearchSelectedMatchGroupWordIds = createSelector(
  getSearchSelectedMatchGroupWords,
  (words) => words.map(({ id }) => id)
)

export const getSearchSelectedMatchStartTime = createSelector(
  getSearchSelectedMatchGroupWords,
  (words) => {
    const [firstWord] = words

    return firstWord?.startTime
  }
)

export const getSearchSelectedMatchParagraphIndex = createSelector(
  getTranscriptionParagraphs,
  getSearchSelectedMatchStartTime,
  (paragraphs, matchStartTime) => {
    const paragraph = paragraphs.find(({
      startTime,
      endTime
    }) => matchStartTime >= startTime && matchStartTime <= endTime)

    if (paragraph) {
      return paragraphs.indexOf(paragraph)
    }

    return null
  }
)