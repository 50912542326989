import { createAction } from '@reduxjs/toolkit'
import { LanguageSelectOptionIds } from 'components/audio-pipeline/FileUpload/LanguageSelect/types'
import {
  PrepareSetSelectedLanguage,
  PrepareSetFileUrl,
  PrepareSetTextValue,
  PrepareStartPollingTranscription,
  PrepareSetFileUploadProgress,
  PrepareSetSelectedFile,
  PrepareSetPlayerCurrentTime,
  PrepareSetPlayerSelectedWordId,
  PrepareSetEditedWord,
  PrepareConsumeTranscription,
  PrepareSetSpeakerNamesAction,
  PrepareSetReplaceAllOnEdit,
  PrepareSetEditedWordId,
  PrepareSetEditedWordText,
  PrepareStartEditWord
} from 'redux/actions/audio-pipeline/types'
import {
  SET_SELECTED_LANGUAGE,
  SET_FILE_URL,
  SET_SUMMARISED_TEXT,
  SET_HEADLINE_TAILORING_TEXT,
  SET_GENERATED_HEADLINE_TEXT,
  SET_GENERATED_ARTICLE_TEXT,
  SET_SEO_TAGS_TEXT,
  TRANSCRIBE,
  START_POLLING_TRANSCRIPTION,
  STOP_POLLING_TRANSCRIPTION,
  SET_SPEAKER_NAMES_FIELD_TEXT,
  SUMMARISE,
  GENERATE_ARTICLE,
  GENERATE_SEO_TAGS,
  GENERATE_HEADLINE,
  SET_FILE_UPLOAD_PROGRESS,
  SET_SELECTED_FILE,
  CLEAR_INPUT_FIELDS,
  SET_PLAYER_CURRENT_TIME,
  SET_PLAYER_SELECTED_WORD_ID,
  START_PLAYBACK,
  STOP_PLAYBACK,
  SET_EDITED_WORD,
  REVERT_EDITED_WORD,
  START_EDIT_WORD,
  STOP_EDIT_WORD,
  SET_EDITED_WORD_ID,
  SET_EDITED_WORD_TEXT,
  CONSUME_TRANSCRIPTION,
  COPY_TRANSCRIPT_TEXT,
  SET_SPEAKER_NAMES,
  SET_REPLACE_ALL_ON_EDIT
} from 'redux/actions/audio-pipeline/constants'
import {
  TranscriptionSpeakerMonolog,
  TranscriptionWord
} from 'components/audio-pipeline/TranscribedText/Transcript/types'

export const setSelectedLanguage = createAction<PrepareSetSelectedLanguage>(SET_SELECTED_LANGUAGE,
  (language: LanguageSelectOptionIds) => ({
    payload: { language }
  }))

export const setFileUrl = createAction<PrepareSetFileUrl>(SET_FILE_URL,
  (value: string) => ({
    payload: { value }
  }))

export const consumeTranscription = createAction<PrepareConsumeTranscription>(
  CONSUME_TRANSCRIPTION,
  (transcription: TranscriptionSpeakerMonolog[]) => ({
    payload: { transcription }
  }))

export const setSummarisedText = createAction<PrepareSetTextValue>(SET_SUMMARISED_TEXT,
  (text: string) => ({
    payload: { text }
  }))

export const setHeadlineTailoringText = createAction<PrepareSetTextValue>(SET_HEADLINE_TAILORING_TEXT,
  (text: string) => ({
    payload: { text }
  }))

export const setGeneratedHeadlineText = createAction<PrepareSetTextValue>(SET_GENERATED_HEADLINE_TEXT,
  (text: string) => ({
    payload: { text }
  }))

export const setGeneratedArticleText = createAction<PrepareSetTextValue>(SET_GENERATED_ARTICLE_TEXT,
  (text: string) => ({
    payload: { text }
  }))

export const setSEOTagsText = createAction<PrepareSetTextValue>(SET_SEO_TAGS_TEXT,
  (text: string) => ({
    payload: { text }
  }))

export const setSpeakerNamesFieldText = createAction<PrepareSetTextValue>(SET_SPEAKER_NAMES_FIELD_TEXT,
  (text: string) => ({
    payload: { text }
  }))

export const setSpeakerNames = createAction<PrepareSetSpeakerNamesAction>(SET_SPEAKER_NAMES,
  (speakerNames: string[]) => ({
    payload: { speakerNames }
  }))

export const transcribe = createAction(TRANSCRIBE)

export const summarise = createAction(SUMMARISE)

export const generateArticle = createAction(GENERATE_ARTICLE)

export const generateSEOTags = createAction(GENERATE_SEO_TAGS)

export const generateHeadline = createAction(GENERATE_HEADLINE)

export const startPollingTranscription = createAction<PrepareStartPollingTranscription>(
  START_POLLING_TRANSCRIPTION,
  (id: string) => ({
    payload: { id }
  }))

export const stopPollingTranscription = createAction(STOP_POLLING_TRANSCRIPTION)

export const setFileUploadProgress = createAction<PrepareSetFileUploadProgress>(
  SET_FILE_UPLOAD_PROGRESS,
  (percent: number) => ({
    payload: { percent }
  }))

export const setSelectedFile = createAction<PrepareSetSelectedFile>(
  SET_SELECTED_FILE,
  (file: File | null) => ({
    payload: { file }
  }))

export const setPlayerCurrentTime = createAction<PrepareSetPlayerCurrentTime>(
  SET_PLAYER_CURRENT_TIME,
  (timestamp: number) => ({
    payload: { timestamp }
  }))

export const setPlayerSelectedWordId = createAction<PrepareSetPlayerSelectedWordId>(
  SET_PLAYER_SELECTED_WORD_ID,
  (id: string | null) => ({
    payload: { id }
  }))

export const startPlayback = createAction(START_PLAYBACK)

export const stopPlayback = createAction(STOP_PLAYBACK)

export const startEditWord = createAction<PrepareStartEditWord>(START_EDIT_WORD, (id: string) => ({
  payload: { id }
}))

export const stopEditWord = createAction<PrepareStartEditWord>(STOP_EDIT_WORD, (id: string) => ({
  payload: { id }
}))

export const setEditedWord = createAction<PrepareSetEditedWord>(SET_EDITED_WORD, (word: TranscriptionWord) => ({
  payload: word
}))

export const revertEditedWord = createAction(REVERT_EDITED_WORD, (id: string) => ({
  payload: { id }
}))

export const clearInputFields = createAction(CLEAR_INPUT_FIELDS)

export const copyTranscriptText = createAction(COPY_TRANSCRIPT_TEXT)

export const setReplaceAllOnEdit = createAction<PrepareSetReplaceAllOnEdit>(SET_REPLACE_ALL_ON_EDIT, (value: boolean) => ({
  payload: { value }
}))

export const setEditedWordId = createAction<PrepareSetEditedWordId>(
  SET_EDITED_WORD_ID, (id: string | null) => ({
    payload: { id }
  }))

export const setEditedWordText = createAction<PrepareSetEditedWordText>(
  SET_EDITED_WORD_TEXT, (text: string) => ({
    payload: { text }
  }))