import React, { useCallback, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { Input } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { SearchControls } from 'components/audio-pipeline/TranscribedText/Transcript/SearchBar/SearchControls'
import { setSearchSelectedMatchId, setSearchText } from 'redux/actions/transcript-search'
import {
  getTranscriptionSearchText
} from 'redux/selectors/transcript-search'
import styles from './styles.module.css'

export const TranscriptionSearchBar: React.FC = () => {
  const dispatch = useDispatch()
  const { formatMessage } = useIntl()
  const searchText = useSelector(getTranscriptionSearchText)
  const placeholder = formatMessage({
    id: 'component.transcript-search.search-input.placeholder'
  })

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setSearchText(e.target.value))
  }, [dispatch])

  useEffect(() => {
    if (!searchText) {
      dispatch(setSearchSelectedMatchId(null))
    }
  }, [dispatch, searchText])

  return (
    <div className={styles.wrapper}>
      <SearchOutlined className={styles.icon}/>
      <Input
        value={searchText}
        onChange={handleChange}
        placeholder={placeholder}
        className={styles.input}
      />
      {searchText && <SearchControls />}
    </div>
  )
}