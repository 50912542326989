import { AudioPipelineStateType } from 'redux/reducers/audio-pipeline/types'
import { LanguageSelectOptionIds } from 'components/audio-pipeline/FileUpload/LanguageSelect/types'

export const AudioPipelineInitialState: AudioPipelineStateType = {
  language: LanguageSelectOptionIds.en_GB,
  fileUrl: '',
  transcription: [],
  summarisedText: '',
  headlineTailoringText: '',
  generatedHeadlineText: '',
  generatedArticleText: '',
  SEOTagsText: '',
  speakerNamesFieldText: '',
  speakerNames: [],
  fileUploadProgress: 0,
  file: null,
  playerCurrentTime: 0,
  playerCurrentWordId: null,
  isPlaybackStarted: false,
  editedWordId: null,
  editedWordText: '',
  editedWordsById: {},
  replaceAllOnEdit: false
}