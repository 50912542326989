import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { PrevNextControls } from 'components/audio-pipeline/TranscribedText/Transcript/SearchBar/SearchControls/PrevNextControls'
import { ClearButton } from 'components/audio-pipeline/TranscribedText/Transcript/SearchBar/SearchControls/ClearButton'
import { MatchCount } from 'components/audio-pipeline/TranscribedText/Transcript/SearchBar/SearchControls/MatchCount'
import { getTranscriptionSearchMatchGroupIds, } from 'redux/selectors/transcript-search'
import { setSearchSelectedMatchId } from 'redux/actions/transcript-search'
import styles from './styles.module.css'

export const SearchControls: React.FC = () => {
  const dispatch = useDispatch()
  const matchGroupIds = useSelector(getTranscriptionSearchMatchGroupIds)

  useEffect(() => {
    const [firstGroupId = null] = matchGroupIds

    dispatch(setSearchSelectedMatchId(firstGroupId))
  }, [dispatch, matchGroupIds])

  return (
    <div className={styles.wrapper}>
      <MatchCount />
      <PrevNextControls />
      <ClearButton />
    </div>
  )
}